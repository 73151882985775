import { Controller } from '@hotwired/stimulus'
import Swiper, { Navigation, Thumbs, Pagination, Lazy } from 'swiper/core'

Swiper.use([Navigation, Thumbs, Pagination, Lazy])
export default class extends Controller {
  static targets = ['large']

  connect () {
    if (this.hasLargeTarget) {
      this.slider = new Swiper(this.largeTarget, {
        loop: false,
        centeredSlides: false,
        slidesPerView: 4,
        spaceBetween: 10,
        lazyLoading: true,
        navigation: {
          nextEl: '#swiperButtonNext',
          prevEl: '#swiperButtonPrev',
        },
      })
    }
  }
}
