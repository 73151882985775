import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  facilityId () {
    return parseInt(this.element.dataset.facilityid)
  }

  className () {
    return this.element.dataset.classname
  }

  connect () {
    const ids = JSON.parse(Cookies.get('check_nursing_facility_ids') || '[]')
    if (Array.isArray(ids) && ids.indexOf(this.facilityId()) !== -1) { this.element.classList.add(`${this.className()}::bookmarked`) }
  }
}
