import { Controller } from '@hotwired/stimulus'
import SmoothScroll from 'smooth-scroll'

export default class extends Controller {
  static targets = ['container']

  initialize () {
    this.scroll = new SmoothScroll(null, {
      offset: 55,
      speed: 600,
      speedAsDuration: true,
      easing: 'linear',
      updateURL: false,
      popstate: false,
    })

    let lastScrollPosition = 0
    let ticking = false

    window.addEventListener('scroll', () => {
      lastScrollPosition = window.scrollY

      if (!ticking) {
        window.requestAnimationFrame(() => {
          this.toggleVisibility(lastScrollPosition)
          ticking = false
        })

        ticking = true
      }
    })
  }

  className () {
    return this.element.dataset.classname
  }

  toggleVisibility (scrollPosition) {
    if (scrollPosition > 400) {
      console.log('scroll')
      this.containerTarget.classList.add(`${this.className()}::visible`)
    } else {
      this.containerTarget.classList.remove(`${this.className()}::visible`)
    }
  }
}
