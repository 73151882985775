import { Controller } from '@hotwired/stimulus'
import Swiper, { Navigation, Thumbs, Pagination, Lazy } from 'swiper/core'

Swiper.use([Navigation, Thumbs, Pagination, Lazy])
export default class extends Controller {
  static targets = ['largeModal', 'modal']

  connect () {
    if (this.hasLargeModalTarget) {
      const imagesCount = this.largeModalTarget.dataset.imagesCount
      this.modalSlider = new Swiper(this.largeModalTarget, {
        loop: false,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
          formatFractionTotal: function (number) {
            return imagesCount
          },
        },
        navigation: {
          nextEl: '#swiperModalButtonNext',
          prevEl: '#swiperModalButtonPrev',
        },
      })
    }
  }

  get className () {
    return this.modalTarget.dataset.classname
  }

  show (e) {
    e.preventDefault()

    const imageSrc = e.target.getAttribute('src')
    const index = Number(e.currentTarget.dataset.index)

    if (imageSrc) {
      this.modalTarget.classList.add(`${this.className}::visible`)
      this.modalSlider.slideTo(index)
    }
  }

  hide (e) {
    e.preventDefault()

    this.modalTarget.classList.remove(`${this.className}::visible`)
  }
}
