import { Controller } from '@hotwired/stimulus'

const VIEW_LIMIT = 6

export default class extends Controller {
  static targets = ['restPhotos', 'open', 'close', 'classificationIndex']

  className () {
    return this.element.dataset.classname
  }

  restToggle (event) {
    const classificationIndex = event.target.dataset.classificationIndex
    const restPhotosTargetElement = this.restPhotosTargets.find((target) => target.dataset.classificationIndex === classificationIndex)
    if (restPhotosTargetElement.classList.contains(`${this.className()}::accordion${classificationIndex}-close`)) {
      this.restOpen(classificationIndex, restPhotosTargetElement)
    } else {
      this.restClose(classificationIndex, restPhotosTargetElement)
    }
  }

  restOpen (classificationIndex, restPhotosTargetElement) {
    restPhotosTargetElement.style.maxHeight = `${restPhotosTargetElement.scrollHeight}px`
    restPhotosTargetElement.classList.remove(`${this.className()}::accordion${classificationIndex}-close`)
    restPhotosTargetElement.classList.add(`${this.className()}::accordion${classificationIndex}-open`)
    this.openTargets.find(target => target.dataset.classificationIndex === classificationIndex).style.display = 'none'
    this.closeTargets.find(target => target.dataset.classificationIndex === classificationIndex).style.display = 'flex'
  }

  restClose (classificationIndex, restPhotosTargetElement) {
    restPhotosTargetElement.style.maxHeight = '0px'
    restPhotosTargetElement.classList.remove(`${this.className()}::accordion${classificationIndex}-open`)
    restPhotosTargetElement.classList.add(`${this.className()}::accordion${classificationIndex}-close`)
    this.openTargets.find(target => target.dataset.classificationIndex === classificationIndex).style.display = 'flex'
    this.closeTargets.find(target => target.dataset.classificationIndex === classificationIndex).style.display = 'none'
  }
}
