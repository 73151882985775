import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'tab', 'panel']

  get className () {
    return this.element.dataset.classname
  }

  initialize () {
    this.tabTargets[0].classList.add(`${this.className}::active`)
    this.panelTargets[0].classList.remove(`${this.className}::hidden`)
  }

  planToggle () {
    if (this.element.classList.contains(`${this.className}::accordion-close`)) {
      this.planOpen()
    } else {
      this.planClose()
    }
  }

  planOpen () {
    this.element.classList.remove(`${this.className}::accordion-close`)
    this.element.classList.add(`${this.className}::accordion-open`)
    this.buttonTarget.textContent = '詳細を閉じる'
    window.gtag('event', 'tap_plan')
  }

  planClose () {
    this.element.classList.remove(`${this.className}::accordion-open`)
    this.element.classList.add(`${this.className}::accordion-close`)
    this.buttonTarget.textContent = '詳細を見る'
  }

  changePanel (e) {
    this.openPanel(e.currentTarget)
  }

  openPanel (targetTab) {
    const currentIndex = this.tabTargets.indexOf(targetTab)

    this.inactiveAllButton()
    this.closeAllPanel()
    targetTab.classList.add(`${this.className}::active`)
    this.panelTargets[currentIndex].classList.remove(`${this.className}::hidden`)
    this.currentButton = targetTab
  }

  inactiveAllButton () {
    this.tabTargets.forEach(button => {
      button.classList.remove(`${this.className}::active`)
    })
  }

  closeAllPanel () {
    this.panelTargets.forEach(panel => {
      panel.classList.add(`${this.className}::hidden`)
    })
  }
}
