import { Controller } from '@hotwired/stimulus'
import SmoothScroll from 'smooth-scroll'

export default class extends Controller {
  initialize () {
    this.scroll = new SmoothScroll(null, {
      offset: 55,
      speed: 600,
      speedAsDuration: true,
      easing: 'linear',
      updateURL: false,
      popstate: false,
    })
  }

  scrollTo (id) {
    const anchor = document.querySelector(id)
    this.scroll.animateScroll(anchor)
  }

  scrollOrRedirectToPlans (e) {
    const plansSection = document.getElementById('plans')
    if (plansSection) {
      e.preventDefault()
      this.scrollTo('#plans')
    }
  }
}
