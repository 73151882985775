import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  componentName = 'viewcomponent::site::parts::nursing_facilities::charm::desktop'

  open () {
    document.getElementById('charm-inner').classList.remove(this.componentName + '::hidden-field')
    document.getElementById('charm-close-button').classList.remove(this.componentName + '::d-none')
    document.getElementById('charm-open-button').classList.add(this.componentName + '::d-none')
  }

  close () {
    document.getElementById('charm-inner').classList.add(this.componentName + '::hidden-field')
    document.getElementById('charm-close-button').classList.add(this.componentName + '::d-none')
    document.getElementById('charm-open-button').classList.remove(this.componentName + '::d-none')
  }
}
