import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'modal',
  ]

  get className () {
    return this.element.dataset.classname
  }
}
