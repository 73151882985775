import { Controller } from '@hotwired/stimulus'
import Swiper, { Navigation, Thumbs, Pagination, Lazy } from 'swiper/core'

Swiper.use([Navigation, Thumbs, Pagination, Lazy])
export default class extends Controller {
  static targets = ['blocks', 'moreButton']

  get className () {
    return this.element.dataset.classname
  }

  initialize () {
    const url = new URL(window.location.href)
    const hash = url.hash.substring(1)

    if (this.hasBlocksTarget) {
      this.blocksTargets.forEach(blocksElement => {
        const childBlocks = blocksElement.querySelectorAll('[data-name="block"]').length

        if (childBlocks > 1) {
          this.blocksTarget.classList.add(`${this.className}::accordion-close`)
          this.moreButtonTarget.classList.add(`${this.className}::close`)
          this.moreButtonTarget.style.display = 'block'
        } else {
          this.moreButtonTarget.style.display = 'none'
        }
      })
    }

    if (hash) {
      const target = document.querySelectorAll('#' + hash)
      target[0].querySelectorAll('[data-name="blocks"]')[0].classList.remove(`${this.className}::accordion-close`)
      target[0].querySelectorAll('[data-name="blocks"]')[0].classList.add(`${this.className}::accordion-open`)
      target[0].querySelectorAll('[data-name="more"]')[0].classList.remove(`${this.className}::close`)
      target[0].querySelectorAll('[data-name="more"]')[0].classList.add(`${this.className}::open`)
      target[0].querySelectorAll('[data-name="more"]')[0].textContent = '閉じる'
    }
  }

  toggle () {
    if (this.blocksTarget.classList.contains(`${this.className}::accordion-close`)) {
      this.open()
    } else {
      this.close()
    }
  }

  open () {
    this.blocksTarget.classList.remove(`${this.className}::accordion-close`)
    this.blocksTarget.classList.add(`${this.className}::accordion-open`)
    this.moreButtonTarget.classList.remove(`${this.className}::close`)
    this.moreButtonTarget.classList.add(`${this.className}::open`)
    this.moreButtonTarget.textContent = '閉じる'
  }

  close () {
    this.blocksTarget.classList.remove(`${this.className}::accordion-open`)
    this.blocksTarget.classList.add(`${this.className}::accordion-close`)
    this.moreButtonTarget.classList.add(`${this.className}::close`)
    this.moreButtonTarget.classList.remove(`${this.className}::open`)
    this.moreButtonTarget.textContent = '続きを見る'
  }
}
