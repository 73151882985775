import { Controller } from '@hotwired/stimulus'
import SmoothScroll from 'smooth-scroll'

export default class extends Controller {
  static targets = ['container', 'panorama', 'classificationFirst', 'classificationSecond', 'classificationThird']

  initialize () {
    this.scroll = new SmoothScroll(null, {
      offset: 55,
      speed: 600,
      speedAsDuration: true,
      easing: 'linear',
      updateURL: false,
      popstate: false,
    })

    let lastScrollPosition = 0
    let ticking = false

    window.addEventListener('scroll', () => {
      lastScrollPosition = window.scrollY

      if (!ticking) {
        window.requestAnimationFrame(() => {
          this.toggleVisibility(lastScrollPosition)
          ticking = false
        })

        ticking = true
      }
    })

    this.doWhenIntersect(document.querySelector('#panorama'), this.panoramaTarget)
    this.doWhenIntersect(document.querySelector('#classification-0'), this.classificationFirstTarget)
    this.doWhenIntersect(document.querySelector('#classification-1'), this.classificationSecondTarget)
    this.doWhenIntersect(document.querySelector('#classification-2'), this.classificationThirdTarget)
  }

  className () {
    return this.element.dataset.classname
  }

  doWhenIntersect (targetSection, targetTab) {
    if (!targetSection) return

    const callback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          targetTab.classList.add(`${this.className()}::active`)
        } else {
          targetTab.classList.remove(`${this.className()}::active`)
        }
      })
    }

    const observer = new IntersectionObserver(callback, {
      rootMargin: '0px',
      threshold: 0.2,
    })
    observer.observe(targetSection)
  }

  toggleVisibility (scrollPosition) {
    if (scrollPosition > 400) {
      this.containerTarget.classList.add(`${this.className()}::visible`)
    } else {
      this.containerTarget.classList.remove(`${this.className()}::visible`)
    }
  }

  scrollTo (id) {
    const anchor = document.querySelector(id)
    this.scroll.animateScroll(anchor)
  }

  scrollToPanorama () {
    this.scrollTo('#panorama')
  }

  scrollToClassificationFirst () {
    this.scrollTo('#classification-0')
  }

  scrollToClassificationSecond () {
    this.scrollTo('#classification-1')
  }

  scrollToClassificationThird () {
    this.scrollTo('#classification-2')
  }
}
