import { Controller } from '@hotwired/stimulus'
import SmoothScroll from 'smooth-scroll'

export default class extends Controller {
  static targets = ['container', 'basic', 'plans', 'photos', 'reviews', 'medicalCares', 'access']

  initialize () {
    this.scroll = new SmoothScroll(null, {
      offset: 55,
      speed: 600,
      speedAsDuration: true,
      easing: 'linear',
      updateURL: false,
      popstate: false,
    })

    let lastScrollPosition = 0
    let ticking = false

    window.addEventListener('scroll', () => {
      lastScrollPosition = window.scrollY

      if (!ticking) {
        window.requestAnimationFrame(() => {
          this.toggleVisibility(lastScrollPosition)
          ticking = false
        })

        ticking = true
      }
    })

    if ('IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
      this.doWhenIntersect(document.querySelector('#basic'), this.basicTarget)
      this.doWhenIntersect(document.querySelector('#plans-view'), this.plansTarget) // 要素が長すぎるため監視位置調整
      this.doWhenIntersect(document.querySelector('#photos'), this.photosTarget)
      this.doWhenIntersect(document.querySelector('#reviews'), this.reviewsTarget)
      this.doWhenIntersect(document.querySelector('#supported_medical_cares'), this.medicalCaresTarget)
      this.doWhenIntersect(document.querySelector('#access'), this.accessTarget)
      this.admissionConditionObserveIntersection()
    }

    this.tabPosition = this.containerTarget.offsetTop
  }

  className () {
    return this.element.dataset.classname
  }

  doWhenIntersect (targetSection, targetTab) {
    if (!targetSection) return

    const callback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          targetTab.classList.add(`${this.className()}::active`)
        } else {
          targetTab.classList.remove(`${this.className()}::active`)
        }
      })
    }

    const observer = new IntersectionObserver(callback, {
      rootMargin: '-50% 0px',
      threshold: 0,
    })
    observer.observe(targetSection)
  }

  admissionConditionObserveIntersection () {
    const targetElement = document.querySelector('#admission_condition')

    if (!targetElement) return
    let fired = false

    const callback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !fired) {
          document.getElementById('view_condition_area').click()
          console.log('Intersection occurred!')
          fired = true
          observer.unobserve(entry.target)
        }
      })
    }

    const observer = new IntersectionObserver(callback, {
      rootMargin: '-50px 0px 50px 0px',
      threshold: 0,
    })
    observer.observe(targetElement)
  }

  toggleVisibility (scrollPosition) {
    if (scrollPosition > this.tabPosition) {
      this.containerTarget.classList.add(`${this.className()}::visible`)
    } else {
      this.containerTarget.classList.remove(`${this.className()}::visible`)
    }
  }

  scrollTo (id) {
    const anchor = document.querySelector(id)
    this.scroll.animateScroll(anchor)
  }

  scrollToBasic () {
    this.scrollTo('#basic')
  }

  scrollToFeatures () {
    this.scrollTo('#features')
  }

  scrollToPlans () {
    this.scrollTo('#plans')
  }

  scrollToPhotos () {
    this.scrollTo('#photos')
  }

  scrollToReviews () {
    this.scrollTo('#reviews')
  }

  scrollToMedicalCares () {
    this.scrollTo('#supported_medical_cares')
  }

  scrollToAccess () {
    this.scrollTo('#access')
  }

  scrollToBuilding () {
    this.scrollTo('#building')
  }
}
