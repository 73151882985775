import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'
import uniq from 'lodash.uniq'

export default class extends Controller {
  static targets = ['overlay', 'catchphrase', 'hide', 'show']

  connect () {
    if (this.modalId() === Cookies.get('verified_modal_id')) {
      this.disableMyFacilitiesModal()
      this.disableInquiryModal()
    }

    this.overlayTargets.forEach((target) => {
      target.classList.add('viewcomponent::site::parts::facility::list_item::desktop::visible')
    })

    const rank = document.querySelectorAll('.rank-label')
    if (rank && rank.length > 0) {
      rank.forEach((target, index) => {
        target.textContent = (index + 1).toString()
      })
    }

    if (this.hasCatchphraseTarget) {
      this.hide()
    }
  }

  classNames () {
    return JSON.parse(this.element.dataset.classnames)
  }

  className () {
    return this.element.dataset.classname
  }

  facilityName () {
    return this.element.dataset.facilityname
  }

  modalId () {
    return this.element.dataset.modalid
  }

  controllerForAdding () {
    return this.element.dataset.controllerforadding
  }

  controllerForUpdating () {
    return this.element.dataset.controllerforupdating
  }

  show () {
    this.catchphraseTargets.forEach((catchphrase) => {
      catchphrase.classList.remove(`${this.className()}::hidden`)
    })
    this.showTarget.style.display = 'none'
    this.hideTarget.style.display = 'flex'
  }

  hide () {
    this.catchphraseTargets.forEach((catchphrase) => {
      catchphrase.classList.add(`${this.className()}::hidden`)
    })
    this.showTarget.style.display = 'flex'
    this.hideTarget.style.display = 'none'
  }

  hideOverlay (e) {
    e.target.classList.remove('viewcomponent::site::parts::facility::list_item::desktop::visible')
  }

  add () {
    const id = Number(this.element.dataset.facilityid)
    const ids = JSON.parse(Cookies.get('check_nursing_facility_ids') || '[]')
    ids.push(id)
    Cookies.set('check_nursing_facility_ids', JSON.stringify(uniq(ids)))

    this.classNames().forEach((className) => {
      const targetElement = this.element.getElementsByClassName(`${className}::container`)[0]
      targetElement.classList.add(`${className}::bookmarked`)
    })
  }

  remove () {
    if (this.isMyFacilitiesPage()) {
      const result = window.confirm(`${this.facilityName()}を気になるリストから削除しますか？`)
      if (result) {
        this.removeId()
        window.location.reload()
      }
      return
    }

    this.removeId()

    this.classNames().forEach((className) => {
      const targetElement = this.element.getElementsByClassName(`${className}::container`)[0]
      targetElement.classList.remove(`${className}::bookmarked`)
    })
  }

  removeId () {
    const id = Number(this.element.dataset.facilityid)
    const ids = this.facilityIds()
    ids.splice(ids.indexOf(id), 1)
    Cookies.set('check_nursing_facility_ids', JSON.stringify(uniq(ids)))
  }

  isMyFacilitiesPage () {
    return window.location.pathname === '/my/facilities/'
  }

  facilityIds () {
    return JSON.parse(Cookies.get('check_nursing_facility_ids') || '[]')
  }

  disableMyFacilitiesModal () {
    this.element.querySelectorAll('.myfacilities-with-modal').forEach((element) => {
      element.dataset.action = `click->${this.controllerForAdding()}#add click->${this.controllerForUpdating()}#update`
    })
  }

  disableInquiryModal () {
    this.element.querySelectorAll('.inquiry-with-modal').forEach((element) => {
      element.dataset.action = ''
    })
  }
}
