import { Controller } from '@hotwired/stimulus'

const DEFAULT_REGION_ID = 3

export default class extends Controller {
  static targets = ['region', 'select']

  get className () {
    return this.element.dataset.classname
  }

  initialize () {
    this.selectTarget.value = DEFAULT_REGION_ID
    this.visibleRegion(DEFAULT_REGION_ID)
  }

  changeRegion (e) {
    e.preventDefault()

    this.visibleRegion(Number(this.selectTarget.value))
  }

  visibleRegion (regionId) {
    this.regionTargets.forEach(region => {
      if (Number(region.dataset.regionid) !== regionId) {
        region.style.display = 'none'
      } else {
        region.style.display = 'block'
      }
    })
  }

  mouseenterTab (e) {
    e.target.classList.add(`${this.className}::is-hover`)
  }

  mouseleaveTab (e) {
    if (e.relatedTarget) {
      e.target.classList.remove(`${this.className}::is-hover`)
    }
  }
}
