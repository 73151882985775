import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['overlay']

  connect () {
    this.overlayTargets.forEach((target) => {
      target.classList.add('viewcomponent::site::pages::facility::summary::desktop::visible')
    })
  }

  hide (e) {
    e.target.classList.remove('viewcomponent::site::pages::facility::summary::desktop::visible')
  }
}
