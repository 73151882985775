import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  connect () {
    if (this.modalId() === Cookies.get('verified_modal_id')) {
      this.disableMyFacilitiesModal()
      this.disableInquiryModal()
    }
  }

  modalId () {
    return this.element.dataset.modalid
  }

  controllerForAdding () {
    return this.element.dataset.controllerforadding
  }

  controllerForUpdating () {
    return this.element.dataset.controllerforupdating
  }

  disableMyFacilitiesModal () {
    this.element.querySelectorAll('.myfacilities-with-modal').forEach((element) => {
      element.dataset.action = `click->${this.controllerForAdding()}#add click->${this.controllerForUpdating()}#update`
    })
  }

  disableInquiryModal () {
    this.element.querySelectorAll('.inquiry-with-modal').forEach((element) => {
      element.dataset.action = ''
    })
  }
}
