import { Controller } from '@hotwired/stimulus'

const VIEW_LIMIT = 3

export default class extends Controller {
  static targets = ['roomTypeSelect', 'careLevelSelect', 'careInsuranceRateSelect', 'plan', 'planNone', 'plansOpen', 'plansClose', 'kaigoHoken', 'step1', 'step2']
  static values = {
    unit: Array,
    category: Number,
    areaPrice: Number,
  }

  initialize () {
    this.planTargets.forEach((plan, index) => {
      if (index >= VIEW_LIMIT) {
        plan.classList.add(`${this.className}::hidden`)
      } else {
        plan.classList.remove(`${this.className}::hidden`)
      }
    })

    if (this.hasPlansOpenTarget && this.hasPlansCloseTarget) {
      if (this.planTargets.length > VIEW_LIMIT) {
        this.plansOpenTarget.style.display = 'flex'
        this.plansCloseTarget.style.display = 'none'
      } else {
        this.plansOpenTarget.style.display = 'none'
        this.plansCloseTarget.style.display = 'none'
      }
    }

    if (this.hasPlanNoneTarget) {
      this.planNoneTarget.style.display = 'none'
    }

    if (this.hasCareLevelSelectTarget && this.hasCareInsuranceRateSelectTarget) {
      this.filterBySelect()
    }
  }

  get className () {
    return this.element.dataset.classname
  }

  updateLabel () {
    const selectState = this.careLevelSelectTarget.value && this.careInsuranceRateSelectTarget.value
    const checkValues = []
    this.roomTypeSelectTargets.forEach(element => {
      if (element.checked) {
        checkValues.push(Number(element.value))
      }
    })
    const checkState = checkValues.length > 0

    if (selectState && !checkState) {
      this.step1Target.classList.remove(`${this.className}::current`)
      this.step2Target.classList.add(`${this.className}::current`)
    } else if (selectState && checkState) {
      this.step1Target.classList.remove(`${this.className}::current`)
      this.step2Target.classList.remove(`${this.className}::current`)
    } else {
      this.step1Target.classList.add(`${this.className}::current`)
      this.step2Target.classList.remove(`${this.className}::current`)
    }
  }

  filterBySelect () {
    if (this.careLevelSelectTarget.value && !this.careInsuranceRateSelectTarget.value) {
      this.careLevelSelectTarget.classList.remove(`${this.className}::current`)
      this.careInsuranceRateSelectTarget.classList.add(`${this.className}::current`)
    } else if (this.careLevelSelectTarget.value && this.careInsuranceRateSelectTarget.value) {
      this.careLevelSelectTarget.classList.remove(`${this.className}::current`)
      this.careInsuranceRateSelectTarget.classList.remove(`${this.className}::current`)
    } else {
      this.careLevelSelectTarget.classList.add(`${this.className}::current`)
      this.careInsuranceRateSelectTarget.classList.remove(`${this.className}::current`)
    }
    this.updateLabel()
  }

  filterByRoomType () {
    const checkValues = []
    let count = 0

    this.roomTypeSelectTargets.forEach(element => {
      if (element.checked) {
        checkValues.push(Number(element.value))
      }
    })

    if (checkValues.length === 0) {
      this.initialize()
      return
    }

    this.planTargets.forEach(plan => {
      if (checkValues.includes(Number(plan.dataset.roomCategory))) {
        plan.classList.remove(`${this.className}::hidden`)
        count++
      } else {
        plan.classList.add(`${this.className}::hidden`)
      }
    })

    if (this.hasPlanNoneTarget) {
      if (count === 0) {
        this.planNoneTarget.style.display = 'flex'
      } else {
        this.planNoneTarget.style.display = 'none'
      }
    }

    if (this.hasPlansOpenTarget && this.hasPlansCloseTarget) {
      this.plansOpenTarget.style.display = 'none'
      this.plansCloseTarget.style.display = 'none'
    }

    this.updateLabel()
  }

  changeNursingInsurance () {
    if (!this.careLevelSelectTarget.value || !this.careInsuranceRateSelectTarget.value) {
      this.insertKaigoHoken(0)
      return
    }

    const unit = this.unitValue.find(element => element.category_id === Number(this.categoryValue) && element.care_level_id === Number(this.careLevelSelectTarget.value))

    if (unit) {
      const kaigoHoken = Number(this.areaPriceValue) * unit.value * (Number(this.careInsuranceRateSelectTarget.value) * 0.1)
      const kaigoHokenRound = Math.round(kaigoHoken / 1000) / 10
      this.insertKaigoHoken(kaigoHokenRound)
    } else {
      this.insertKaigoHoken(0)
    }
  }

  insertKaigoHoken (kaigoHoken) {
    this.planTargets.forEach(plan => {
      const monthlyFee = (Math.round(Number(plan.dataset.monthlyFee) / 1000) + (kaigoHoken * 10)) / 10
      plan.querySelectorAll('.monthly-fee em').forEach(element => {
        element.innerHTML = this.feeToString(monthlyFee)
      })
    })

    this.kaigoHokenTargets.forEach(element => {
      element.innerHTML = kaigoHoken
    })
  }

  feeToString (tenThousandAmount) {
    if (tenThousandAmount > 10000) {
      const split = String(tenThousandAmount).split('.')
      split[0] = split[0].slice(0, -4) + '億' + split[0].slice(-4)
      return split.join('.')
    } else {
      return String(tenThousandAmount)
    }
  }

  restOpen () {
    this.planTargets.forEach((plan, index) => {
      if (index >= VIEW_LIMIT) {
        plan.classList.remove(`${this.className}::hidden`)
      }
    })

    if (this.hasPlansOpenTarget && this.hasPlansCloseTarget && this.hasPlanNoneTarget) {
      this.plansOpenTarget.style.display = 'none'
      this.plansCloseTarget.style.display = 'flex'
      this.planNoneTarget.style.display = 'none'
    }
  }

  restClose () {
    this.planTargets.forEach((plan, index) => {
      if (index >= VIEW_LIMIT) {
        plan.classList.add(`${this.className}::hidden`)
      }
    })

    if (this.hasPlansOpenTarget && this.hasPlansCloseTarget && this.hasPlanNoneTarget) {
      this.plansOpenTarget.style.display = 'flex'
      this.plansCloseTarget.style.display = 'none'
      this.planNoneTarget.style.display = 'none'
    }
  }
}
