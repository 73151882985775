import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ['modal', 'myFacilitiesModalA', 'inquiryModalA', 'modalB', 'myfacilitiesSentenceB', 'inquirySentenceB']

  modalId () {
    return this.element.dataset.modalid
  }

  controllerForAdding () {
    return this.element.dataset.controllerforadding
  }

  controllerForUpdating () {
    return this.element.dataset.controllerforupdating
  }

  openMyFacilitiesModal (e) {
    e.preventDefault()

    this.modalTarget.style.display = 'flex'
    this.myFacilitiesModalATarget.style.display = 'block'
    this.modalBTarget.style.display = 'none'
  }

  openInquiryModal (e) {
    e.preventDefault()

    this.modalTarget.style.display = 'flex'
    this.inquiryModalATarget.style.display = 'block'
    this.modalBTarget.style.display = 'none'
  }

  closeModal () {
    this.modalTarget.style.display = 'none'
    this.inquiryModalATarget.style.display = 'none'
    this.myFacilitiesModalATarget.style.display = 'none'
    this.modalBTarget.style.display = 'none'
  }

  enableMyFacilitiesModalB () {
    this.inquiryModalATarget.style.display = 'none'
    this.myFacilitiesModalATarget.style.display = 'none'
    this.modalBTarget.style.display = 'block'
    this.myfacilitiesSentenceBTarget.style.display = 'block'
    this.inquirySentenceBTarget.style.display = 'none'
  }

  enableInquiryModalB () {
    this.inquiryModalATarget.style.display = 'none'
    this.myFacilitiesModalATarget.style.display = 'none'
    this.modalBTarget.style.display = 'block'
    this.inquirySentenceBTarget.style.display = 'block'
    this.myfacilitiesSentenceBTarget.style.display = 'none'
  }

  registerVerifiedModalId (e) {
    const modalId = e.target.dataset.modalid
    Cookies.set('verified_modal_id', modalId)
    this.disableMyFacilitiesModal(modalId)
    this.disableInquiryModal(modalId)
  }

  disableMyFacilitiesModal (modalId) {
    if (!this.controllerForAdding() || !this.controllerForUpdating) return

    document.querySelectorAll('.myfacilities-with-modal').forEach((element) => {
      if (element.dataset.modalid === modalId) {
        element.dataset.action = `click->${this.controllerForAdding()}#add click->${this.controllerForUpdating()}#update`
      }
    })
  }

  disableInquiryModal (modalId) {
    document.querySelectorAll('.inquiry-with-modal').forEach((element) => {
      if (element.dataset.modalid === modalId) {
        element.dataset.action = ''
      }
    })
  }
}
