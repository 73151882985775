import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['template', 'modal', 'modalImage', 'container']

  connect () {
    this.containerTarget.insertAdjacentHTML('beforeend', this.templateTarget.innerHTML)
  }

  get className () {
    return this.containerTarget.dataset.classname
  }

  show (e) {
    e.preventDefault()

    const imageSrc = e.target.getAttribute('src')
    if (imageSrc) {
      this.modalTarget.classList.add(`${this.className}::visible`)
      this.modalImageTarget.setAttribute('src', e.target.getAttribute('src'))
    }
  }

  hide (e) {
    e.preventDefault()
    this.containerTarget.removeChild(this.containerTarget.lastElementChild)
    this.containerTarget.insertAdjacentHTML('beforeend', this.templateTarget.innerHTML)
  }
}
