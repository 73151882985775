import { Controller } from '@hotwired/stimulus'
import Swiper, { Navigation, Thumbs, Pagination } from 'swiper/core'

Swiper.use([Navigation, Thumbs, Pagination])

export default class extends Controller {
  static targets = ['seeMoreNote', 'large', 'largeModal', 'modal', 'small']

  connect () {
    const imagesCount = this.largeTarget.dataset.imagesCount

    this.thumbs = new Swiper(this.smallTarget, {
      loop: false,
      slidesPerView: 8,
      slidesPerGroup: 8,
      spaceBetween: 3,
      spaceBefore: 3,
      spaceAfter: 3,
      aspectRatio: 1,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      slideToClickedSlide: true,
    })

    this.slider = new Swiper(this.largeTarget, {
      loop: false,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      slideToClickedSlide: true,
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
        formatFractionTotal: function (number) {
          return imagesCount
        },
      },
      navigation: {
        nextEl: '#swiperButtonNext',
        prevEl: '#swiperButtonPrev',
      },
      thumbs: {
        swiper: this.thumbs,
      },
    })

    this.modalSlider = new Swiper(this.largeModalTarget, {
      loop: false,
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
        formatFractionTotal: function (number) {
          return imagesCount
        },
      },
      navigation: {
        nextEl: '#swiperModalButtonNext',
        prevEl: '#swiperModalButtonPrev',
      },
      thumbs: {
        swiper: this.slider,
      },
      observer: true,
      observeParents: true,
    })
  }

  show (e) {
    e.preventDefault()

    const imageSrc = e.target.getAttribute('src')
    const index = Number(e.currentTarget.dataset.index)
    if (imageSrc) {
      this.modalTarget.classList.add(`${this.element.dataset.classname}::visible`)
    }
  }

  hide (e) {
    e.preventDefault()
    this.modalTarget.classList.remove(`${this.element.dataset.classname}::visible`)
  }
}
