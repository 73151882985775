import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  get className () {
    return this.element.dataset.classname
  }

  toggle (e) {
    const target = e.target.parentNode

    if (target.classList.contains(`${this.className}::accordion-close`)) {
      this.open(target)
    } else {
      this.close(target)
    }
  }

  open (target) {
    target.classList.remove(`${this.className}::accordion-close`)
    target.classList.add(`${this.className}::accordion-open`)
  }

  close (target) {
    target.classList.remove(`${this.className}::accordion-open`)
    target.classList.add(`${this.className}::accordion-close`)
  }
}
