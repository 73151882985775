import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  // 通常のヘッダーに加えてスクロールすると表示される固定ヘッダーがあるため、同名のターゲットが2つずつある
  static targets = ['badge', 'tooltip']

  connect () {
    this.updateBadges()
  }

  count () {
    return JSON.parse(Cookies.get('check_nursing_facility_ids') || '[]').length
  }

  update () {
    if (this.isCountIncreased()) this.flashTooltips()

    this.updateBadges()
  }

  isCountIncreased () {
    return this.count() > parseInt(this.badgeTargets[0].innerHTML)
  }

  flashTooltips () {
    this.tooltipTargets.forEach((tooltip) => {
      tooltip.classList.add('viewcomponent::site::parts::header::my::desktop::fade-in')
      tooltip.style.display = 'block'

      setTimeout(() => {
        tooltip.classList.remove('viewcomponent::site::parts::header::my::desktop::fade-in')
        tooltip.classList.add('viewcomponent::site::parts::header::my::desktop::fade-out')

        setTimeout(() => {
          tooltip.style.display = 'none'
          tooltip.classList.remove('viewcomponent::site::parts::header::my::desktop::fade-out')
        }, 1000) // ここで指定する時間はfadeOutのアニメーション時間と一致させてください
      }, 2000)
    })
  }

  updateBadges () {
    this.badgeTargets.forEach((badge) => { badge.innerHTML = this.count() })
  }
}
