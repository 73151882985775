import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'
import uniq from 'lodash.uniq'

export default class extends Controller {
  static targets = ['facility']

  connect () {
    const ids = JSON.parse(Cookies.get('check_nursing_facility_ids') || '[]')
    if (Array.isArray(ids) && ids.indexOf(this.facilityId()) !== -1) { this.element.classList.add(`${this.className()}::bookmarked`) }
  }

  facilityId () {
    return parseInt(this.element.dataset.facilityid)
  }

  className () {
    return this.element.dataset.classname
  }

  add () {
    const id = Number(this.element.dataset.facilityid)
    const ids = JSON.parse(Cookies.get('check_nursing_facility_ids') || '[]')
    ids.push(id)
    Cookies.set('check_nursing_facility_ids', JSON.stringify(uniq(ids)))

    this.element.classList.add(`${this.className()}::bookmarked`)
  }

  remove () {
    const id = Number(this.element.dataset.facilityid)
    const ids = JSON.parse(Cookies.get('check_nursing_facility_ids') || '[]')
    ids.splice(ids.indexOf(id), 1)
    Cookies.set('check_nursing_facility_ids', JSON.stringify(uniq(ids)))

    this.element.classList.remove(`${this.className()}::bookmarked`)
  }
}
