import { Controller } from '@hotwired/stimulus'

const THUMBS_WIDTH = 900
const MOVE_LENGTH = 600

export default class extends Controller {
  static targets = ['screen', 'prev', 'next', 'thumbs']

  initialize () {
    this.thumbsWidth = this.thumbsTarget.clientWidth
    this.leftPosition = 0
    const thumbsImages = this.thumbsTarget.querySelectorAll('img')

    this.hiddenNav()

    thumbsImages.forEach((thumb) => {
      thumb.addEventListener('click', () => {
        if (pannellum) {
          pannellum.viewer(this.screenTarget, {
            panorama: thumb.getAttribute('data-path'),
            autoLoad: true,
            autoRotate: -2,
            compass: false,
            mouseZoom: false,
            hfov: 120,
          })

          thumbsImages.forEach((thumb) => {
            thumb.classList.remove(`${this.className()}::active`)
          })
          thumb.classList.add(`${this.className()}::active`)
        }
      })
    })

    thumbsImages[0].click()
  }

  className () {
    return this.element.dataset.classname
  }

  prev () {
    const position = -this.leftPosition

    if (position > 0) {
      this.leftPosition += position > MOVE_LENGTH ? MOVE_LENGTH : position
      this.thumbsTarget.style.transform = `translateX(${this.leftPosition}px)`
      this.hiddenNav()
    }
  }

  next () {
    const position = THUMBS_WIDTH - this.leftPosition

    if (position < this.thumbsWidth) {
      this.leftPosition -= this.thumbsWidth - position > MOVE_LENGTH ? MOVE_LENGTH : this.thumbsWidth - position
      this.thumbsTarget.style.transform = `translateX(${this.leftPosition}px)`
      this.hiddenNav()
    }
  }

  hiddenNav () {
    if (this.leftPosition === 0) {
      this.prevTarget.classList.add(`${this.className()}::hidden`)
    } else {
      this.prevTarget.classList.remove(`${this.className()}::hidden`)
    }

    if (-this.leftPosition + THUMBS_WIDTH >= this.thumbsWidth) {
      this.nextTarget.classList.add(`${this.className()}::hidden`)
    } else {
      this.nextTarget.classList.remove(`${this.className()}::hidden`)
    }
  }
}
