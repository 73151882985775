import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['bulkCheckbox', 'hiddenCheckbox']

  synchChecks (e) {
    this.hiddenCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = e.target.checked
      // 非表示のチェックボックスでchangeイベントを発火させ、計算結果の計算を走らせる
      const changeEvent = new Event('change')
      setTimeout(() => {
        checkbox.dispatchEvent(changeEvent)
      }, 100)
    })

    if (!e.target.checked) {
      this.uncheckNewOpenFeatureCheckboxes(e)
    }
  }

  uncheckBulkCheckbox (e) {
    if (!e.target.checked) {
      this.bulkCheckboxTarget.checked = false
    }
  }

  uncheckNewOpenFeatureCheckboxes (e) {
    if (!e.target.checked) {
      const featureCheckboxTargets = document.querySelectorAll('input[name="feature_content_ids[]"]')
      featureCheckboxTargets.forEach((checkbox) => {
        const featureContentId = checkbox.getAttribute('data-feature-content-id')

        if (checkbox.checked && (featureContentId === '305' || featureContentId === '306')) {
          checkbox.checked = false
          const changeEvent = new Event('change')
          checkbox.dispatchEvent(changeEvent)
        }
      })
    }
  }
}
