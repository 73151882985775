import { Controller } from '@hotwired/stimulus'
import SmoothScroll from 'smooth-scroll'

const VISIBLE_POSITION = 400

export default class extends Controller {
  static targets = ['button']

  get className () {
    return this.element.dataset.classname
  }

  initialize () {
    this.scroll = new SmoothScroll(null, {
      speed: 600,
      speedAsDuration: true,
      easing: 'linear',
      updateURL: false,
      popstate: false,
    })

    let lastScrollPosition = 0
    let ticking = false

    window.addEventListener('scroll', () => {
      lastScrollPosition = window.scrollY

      if (!ticking) {
        window.requestAnimationFrame(() => {
          this.toggleVisibility(lastScrollPosition)
          ticking = false
        })

        ticking = true
      }
    })
  }

  toggleVisibility (scrollPosition) {
    if (scrollPosition > VISIBLE_POSITION) {
      this.buttonTarget.classList.add(`${this.className}::visible`)
    } else {
      this.buttonTarget.classList.remove(`${this.className}::visible`)
    }
  }

  scrollTo () {
    this.scroll.animateScroll(document.documentElement)
  }
}
