import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['scroll', 'tab', 'panel', 'accordion', 'checkAllAccordion']

  className () {
    return this.element.dataset.classname
  }

  toggleModal (e) {
    e.preventDefault()

    if (this.modalTarget.classList.contains(`${this.className()}::modal-open`)) {
      this.modalTarget.classList.remove(`${this.className()}::modal-open`)
    } else {
      this.modalTarget.classList.add(`${this.className()}::modal-open`)
    }
  }

  reset () {
    this.tabTargets.forEach(button => {
      button.classList.remove(`${this.className()}::active`)
    })

    this.panelTargets.forEach(panel => {
      if (panel.dataset.panel === 'tab-id-01') {
        panel.classList.add(`${this.className()}::hidden`)
      }
    })
  }

  changePanel (e) {
    this.openPanel(e.currentTarget)
  }

  openPanel (targetTab) {
    const tabId = targetTab.dataset.tab
    const tabs = this.tabTargets.filter(element => element.dataset.tab === tabId)
    const panels = this.panelTargets.filter(element => element.dataset.panel === tabId)

    const currentPanel = this.panelTargets.find(element => element.classList.contains(`${this.className()}::current`))
    const currentId = currentPanel ? currentPanel.dataset.panel : null
    const currentPanels = this.panelTargets.filter(element => element.dataset.panel === currentId)

    const currentIndex = tabs.indexOf(targetTab)

    this.hideAllTabs(tabs)
    this.closeAllPanel(panels)

    panels[currentIndex].classList.remove(`${this.className()}::hidden`)

    if (tabId === 'tab-id-01') {
      targetTab.classList.add(`${this.className()}::active`)
      document.getElementById('panel-container').scrollTo({ top: 0, left: 0 })
    } else {
      if (currentPanels) {
        currentPanels.forEach(element => {
          element.classList.remove(`${this.className()}::current`)
        })
      }
      panels[currentIndex].classList.add(`${this.className()}::current`)
    }
  }

  hideAllTabs (tabs) {
    tabs.forEach(button => {
      button.classList.remove(`${this.className()}::active`)
      if (button.dataset.tab !== 'tab-id-01') {
        button.classList.add(`${this.className()}::hidden`)
      }
    })
  }

  closeAllPanel (panels) {
    panels.forEach(panel => {
      panel.classList.add(`${this.className()}::hidden`)
      if (panel.dataset.panel !== 'tab-id-01') {
        panel.classList.remove(`${this.className()}::current`)
      }
    })
  }

  scrollToParent (target) {
    const parentElement = document.getElementById('panel-container')
    const parentOffset = parentElement.getBoundingClientRect().top
    const childOffset = target.getBoundingClientRect().top
    const scrollTo = childOffset - parentOffset + parentElement.scrollTop

    parentElement.scroll({
      top: scrollTo,
      behavior: 'smooth',
    })
  }

  toggleAccordion (e) {
    const target = e.target.parentNode

    if (target.classList.contains(`${this.className()}::accordion-close`)) {
      this.open(target)
    } else {
      this.close(target)
    }
  }

  open (target) {
    target.classList.remove(`${this.className()}::accordion-close`)
    target.classList.add(`${this.className()}::accordion-open`)
    setTimeout(() => {
      this.scrollToParent(target)
    }, 200)
  }

  close (target) {
    target.classList.remove(`${this.className()}::accordion-open`)
    target.classList.add(`${this.className()}::accordion-close`)
  }

  checkAllAccordion (e) {
    const allCheckbox = e.currentTarget
    const isChecked = allCheckbox.checked
    const target = this.checkAllAccordionTargets[0].parentNode

    if (isChecked) {
      if (target.classList.contains(`${this.className()}::accordion-close`)) {
        this.open(target)
      } else {
        setTimeout(() => {
          this.scrollToParent(target)
        }, 200)
      }
    }
  }

  back (e) {
    const targetId = e.target.closest('[role="panel"]').dataset.panel

    const panels = this.panelTargets.filter(element => element.dataset.panel === targetId)
    panels.forEach(element => {
      element.classList.remove(`${this.className()}::hidden`)
      element.classList.remove(`${this.className()}::current`)
      element.classList.add(`${this.className()}::hidden`)
    })

    const tabs = this.tabTargets.filter(element => element.dataset.tab === targetId)
    tabs.forEach(button => {
      button.classList.remove(`${this.className()}::hidden`)
    })

    if (targetId === 'tab-id-05') {
      e.target.closest('[role="panel"]').parentNode.closest('[role="panel"]').classList.add(`${this.className()}::current`)
    } else {
      tabs[0].closest('[role="panel"]').classList.add(`${this.className()}::current`)
    }
  }
}
