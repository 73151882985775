import { Controller } from '@hotwired/stimulus'
import Swiper, { Navigation, Pagination, Lazy } from 'swiper/core'

Swiper.use([Navigation, Pagination, Lazy])
export default class extends Controller {
  static targets = ['rank']

  get className () {
    return this.element.dataset.classname
  }

  connect () {
    const swiper = new Swiper(this.element, {
      slidesPerView: 'auto',
      spaceBetween: 10,
      lazyLoading: true,
      lazy: {
        loadPrevNext: true,
      },
      navigation: {
        prevEl: '#swiperButtonPrev',
        nextEl: '#swiperButtonNext',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        bulletActiveClass: `${this.className}::active`,
      },
    })

    swiper.enable()

    const rank = this.rankTargets
    if (rank && rank.length > 0) {
      rank.forEach((target, index) => {
        target.textContent = (index + 1).toString()
      })
    }
  }
}
