import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  componentName = 'viewcomponent::site::parts::nursing_facilities::category_description::desktop'

  connect () {
    this.addHiddenClass()
  }

  addHiddenClass () {
    const minHeight = 150
    const divCount = 3

    for (let i = 0; i < divCount; i++) {
      if (document.getElementById(`category-description-text-container-${i}`).offsetHeight >= minHeight) {
        document.getElementById(`category-description-text-container-${i}`).classList.add(this.componentName + '::hidden-field')
        document.getElementById(`category-description-open-button-${i}`).classList.remove(this.componentName + '::d-none')
      }
    }
  }

  open (event) {
    const index = event.currentTarget.getAttribute('data-value')
    document.getElementById(`category-description-text-container-${index}`).classList.remove(this.componentName + '::hidden-field')
    document.getElementById(`category-description-close-button-${index}`).classList.remove(this.componentName + '::d-none')
    document.getElementById(`category-description-open-button-${index}`).classList.add(this.componentName + '::d-none')
  }

  close (event) {
    const index = event.currentTarget.getAttribute('data-value')
    document.getElementById(`category-description-text-container-${index}`).classList.add(this.componentName + '::hidden-field')
    document.getElementById(`category-description-close-button-${index}`).classList.add(this.componentName + '::d-none')
    document.getElementById(`category-description-open-button-${index}`).classList.remove(this.componentName + '::d-none')
  }
}
