import { Controller } from '@hotwired/stimulus'
import { getCount } from '../api/common/count'

export default class extends Controller {
  static targets = [
    'modal',
    'tab',
    'panel',
    'form',
    'submit',
    'loading',
    'accordion',
    'accordionButtonOpen',
    'accordionButtonClose',
    'notifyText',
    'featureCheckbox',
    'cityCheckbox',
    'routeCheckbox',
    'stationCheckbox',
    'prefectureId',
    'cityId',
    'ordinanceDesignatedCityId',
    'stationId',
    'stationList',
    'cityList',
    'ordinanceDesignatedCityList',
    'citySelectedContent',
    'routeSelectedContent',
    'submitButton',
    'checkboxAll',
    'checkboxes',
  ]

  connect () {
    this.setFacilitiesCount()
  }

  get className () {
    return this.element.dataset.classname
  }

  changeRoutePanel () {
    this.cityIdTargets.forEach((element, _index) => element.remove())
    this.ordinanceDesignatedCityIdTargets.forEach((element, _index) => element.remove())
    this.cityListTargets.forEach((element, _index) => { element.checked = false })
    this.ordinanceDesignatedCityListTargets.forEach((element, _index) => { element.checked = false })
  }

  changeAreaPanel () {
    this.stationIdTargets.forEach((element, _index) => element.remove())
    this.stationListTargets.forEach((element, _index) => { element.checked = false })
  }

  toggleModal (e) {
    e.preventDefault()
    const targetModalIndex = e.target.dataset.modalIndex

    if (this.modalTarget.classList.contains(`${this.className}::modal-open`)) {
      this.modalTarget.classList.remove(`${this.className}::modal-open`)
    } else {
      this.modalTarget.classList.add(`${this.className}::modal-open`)
      this.modalInitialize(targetModalIndex)
    }
  }

  modalInitialize (targetModalIndex) {
    if (this.hasTabTarget) {
      this.tabTargets[targetModalIndex].classList.add('viewcomponent::site::parts::search::modal::desktop::active')
    }
    if (this.hasPanelTarget) {
      this.panelTargets[targetModalIndex].classList.remove('viewcomponent::site::parts::search::modal::desktop::hidden')
    }
  }

  ga () {
    window.gtagEvent('event', '', 'search_tap_modal')
  }

  validate (e) {
    e.preventDefault()

    // city_listとordinance_designated_city_list、station_listのいずれかがチェックされている場合
    if (this.cityListTargets.some((element) => element.checked) ||
      this.ordinanceDesignatedCityListTargets.some((element) => element.checked) ||
      this.stationListTargets.some((element) => element.checked)
    ) {
      // prefecture_idが存在したら削除し、該当のhidden_fieldを削除する
      if (this.prefectureIdTargets.length > 0) {
        this.prefectureIdTargets.forEach((element) => element.remove())
      }
    }

    this.formTarget.submit()
  }

  clickAreaCheckBox (e) {
    // 駅の場合は別路線の同一駅チェックも変更する必要がある
    if (e.target.name === 'station_ids[]') {
      Array.from(this.stationListTargets).filter(stationList => stationList.value === e.target.value).forEach(stationList => { return (stationList.checked = e.target.checked) })
    }

    // 路線・エリアの場合は選択中のものを下部に表示する
    if (['station_ids[]', 'city_ids[]', 'ordinance_designated_city_ids[]'].includes(e.target.name)) {
      this.setSelectedContentList(e)
    }
  }

  changeFormValue (e) {
    this.setFacilitiesCount()
  }

  manipulateString (a, b) {
    // 文字列aに文字列bが含まれているかチェック
    if (a.includes(b)) {
      // 文字列bが含まれている場合は、文字列aから文字列bを削除して返す
      return a.replace(b, '')
    } else {
      // 文字列bが含まれていない場合は、文字列aに文字列bを追加して返す
      return a + ` ${b}`
    }
  }

  setSelectedContentList (e) {
    const selectedContent = e.target.closest('label').querySelector('span').textContent
    if (e.target.name === 'station_ids[]') {
      const replacementText = this.manipulateString(this.routeSelectedContentTarget.textContent, selectedContent)
      this.citySelectedContentTarget.innerHTML = ''
      this.routeSelectedContentTarget.innerHTML = replacementText
    } else {
      const replacementText = this.manipulateString(this.citySelectedContentTarget.textContent, selectedContent)
      this.routeSelectedContentTarget.innerHTML = ''
      this.citySelectedContentTarget.innerHTML = replacementText
    }
  }

  setFacilitiesCount () {
    if (typeof FormData !== 'function') {
      return
    }

    const formData = new FormData(this.formTarget)
    // city_ids, ordinance_designated_city_ids, station_idsのいずれかがチェックされている場合、prefecture_idは検索条件に含めない
    const prefectureId = formData.getAll('city_ids[]').length > 0 || formData.getAll('ordinance_designated_city_ids[]').length > 0 || formData.getAll('station_ids[]').length > 0 ? null : formData.get('prefecture_id')

    const params = {
      prefecture_id: prefectureId,
      city_ids: formData.getAll('city_ids[]'),
      ordinance_designated_city_ids: formData.getAll('ordinance_designated_city_ids[]'),
      station_ids: formData.getAll('station_ids[]'),
      admission_fee_min: formData.get('admission_fee_min'),
      admission_fee_max: formData.get('admission_fee_max'),
      monthly_fee_min: formData.get('monthly_fee_min'),
      monthly_fee_max: formData.get('monthly_fee_max'),
      walking_time: formData.get('walking_time'),
      care_level_ids: formData.getAll('care_level_ids[]'),
      feature_content_ids: formData.getAll('feature_content_ids[]'),
      category_ids: formData.getAll('category_ids[]'),
      medical_care_ids: formData.getAll('medical_care_ids[]'),
      with_review: formData.get('with_review'),
      vacancies_exist: formData.get('vacancies_exist'),
      welfare_acceptable: formData.get('welfare_acceptable'),
      dementia_acceptable: formData.get('dementia_acceptable'),
      no_joint_surety_required: formData.get('no_joint_surety_required'),
    }

    getCount(params).then((count) => {
      const countElement = this.submitTargets[0]
      const startNumber = parseInt(countElement.textContent)
      const endNumber = count.result

      this.submitTargets.forEach((element) => {
        if (count.result >= 10000) {
          element.innerText = '10000+'
          this.notifyFacilitiesCountIsOver()
          this.submitButtonEnabled()
        } else if (count.result <= 0) {
          this.submitButtonDisabled()
          element.innerText = count.result
          window.gtagEvent('event', '', '0_facilities_modal')
        } else {
          this.submitButtonEnabled()
          element.innerText = count.result
          this.animateCount(countElement, startNumber, endNumber)
        }
      })
    })
  }

  submitButtonEnabled () {
    this.submitButtonTarget.disabled = false
    this.submitButtonTarget.classList.remove('viewcomponent::site::parts::search::modal::desktop::disabled')
  }

  submitButtonDisabled () {
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.classList.add('viewcomponent::site::parts::search::modal::desktop::disabled')
  }

  notifyFacilitiesCountIsOver () {
    if (this.hasNotifyTextTarget) {
      this.notifyTextTarget.style.display = 'block'
    }
  }

  animateCount (element, start, end) {
    let startTime = null

    const updateNumber = (timestamp) => {
      if (!startTime) startTime = timestamp
      const progress = Math.min((timestamp - startTime) / 200, 1)
      const currentNumber = Math.floor(start + (end - start) * progress)
      element.textContent = currentNumber

      if (progress < 1) {
        requestAnimationFrame(updateNumber)
      }
    }

    requestAnimationFrame(updateNumber)
  }

  toggleAccordion () {
    if (this.accordionTarget.classList.contains(`${this.className}::accordion-open`)) {
      this.accordionTarget.classList.remove(`${this.className}::accordion-open`)
      this.accordionButtonOpenTarget.classList.remove(`${this.className}::accordion-button-hidden`)
      this.accordionButtonCloseTarget.classList.add(`${this.className}::accordion-button-hidden`)
    } else {
      this.accordionTarget.classList.add(`${this.className}::accordion-open`)
      this.accordionButtonOpenTarget.classList.add(`${this.className}::accordion-button-hidden`)
      this.accordionButtonCloseTarget.classList.remove(`${this.className}::accordion-button-hidden`)
    }
  }

  uncheckAssociatedFeatureCheckboxes (e) {
    if (!e.target.checked) {
      const uncheckFeatureId = e.target.getAttribute('data-feature-content-id')
      this.featureCheckboxTargets.forEach((checkbox) => {
        if (checkbox.checked && checkbox.getAttribute('data-feature-content-id') === uncheckFeatureId) {
          checkbox.checked = false
          const changeEvent = new Event('change')
          checkbox.dispatchEvent(changeEvent)
        }
      })
    }
  }

  hideLoading (target) {
    target.style.display = 'none'
  }

  classNameModal () {
    return 'viewcomponent::site::parts::search::modal::desktop'
  }

  modalDesktopComponentTarget () {
    return 'data-site--parts--search--modal--desktop--component-target'
  }

  desktopComponentTarget () {
    return 'data-site--parts--search--desktop--component-target'
  }

  scrollToParent (target) {
    const parentElement = document.getElementById('panel-container')
    const parentOffset = parentElement.getBoundingClientRect().top
    const childOffset = target.getBoundingClientRect().top
    const scrollTo = childOffset - parentOffset + parentElement.scrollTop

    parentElement.scroll({
      top: scrollTo,
      behavior: 'smooth',
    })
  }

  getPrefecture (e) {
    e.preventDefault()
    const { valuePref } = e.target.dataset
    const loading = this.loadingTargets.filter(element => element.dataset.valuePref === e.target.dataset.valuePref)
    const hasElement = this.cityCheckboxTargets.find(element => element.dataset.valuePref === valuePref)

    if (hasElement && !hasElement.innerHTML) {
      const params = new URLSearchParams()
      params.append('prefecture_ids[]', valuePref)
      const path = '/api/areas/cities?' + params.toString()

      fetch(path)
        .then(data => data.json())
        .then(res => {
          this.hideLoading(loading[0])
          this.cities(res)
          this.scrollToParent(e.target)
        })
    }
  }

  cities (data) {
    // eslint-disable-next-line camelcase
    const { id, cities, ordinance_designated_cities } = data.prefectures[0]
    const prefectureId = String(id)
    const ordinanceDesignatedCitiesList = this.createOrdinanceDesignatedCitiesList(ordinance_designated_cities)
    const citiesList = this.createCityList(cities)
    const target = this.cityCheckboxTargets.find(element => element.dataset.valuePref === prefectureId)
    if (target && !target.innerHTML) {
      target.innerHTML += ordinanceDesignatedCitiesList + citiesList
    }
  }

  shouldCheck (idList, inputTagValue) {
    return idList.includes(inputTagValue)
  }

  // MEMO: input hiddenとinput checkboxどちらのパラメータが送られてしまうのでチェックボックスが生成されたらhidden要素を削除する
  removeInputHidden (idList, inputTagValue) {
    const inputHidden = idList.filter((element, _index) => { return parseInt(element.value) === inputTagValue })[0]
    if (typeof inputHidden !== 'undefined') { inputHidden.remove() }
  }

  createOrdinanceDesignatedCitiesList (ordinanceDesignatedCities) {
    const ordinanceDesignatedCityIdTargets = this.ordinanceDesignatedCityIdTargets
    const ordinanceDesignatedCityIds = ordinanceDesignatedCityIdTargets.map(inputValue => parseInt(inputValue.value))
    return ordinanceDesignatedCities.map(ordinanceDesignatedCity => {
      const checked = this.shouldCheck(ordinanceDesignatedCityIds, ordinanceDesignatedCity.id)
      this.removeInputHidden(ordinanceDesignatedCityIdTargets, ordinanceDesignatedCity.id)
      return `
        <li class="${this.classNameModal()}::checks">
            <label class="${this.classNameModal()}::check">
                <input class="check-input" type="checkbox" value="${ordinanceDesignatedCity.id}" ${checked ? "checked='checked'" : ''} name="ordinance_designated_city_ids[]" data-action="change->site--parts--search--desktop--component#changeFormValue click->site--parts--search--desktop--component#clickAreaCheckBox" data-site--parts--search--desktop--component-target="ordinanceDesignatedCityList">
                <i></i>
                <span>${ordinanceDesignatedCity.name}</span>
            </label>
        </li>`
    }).join('')
  }

  createCityList (cities) {
    const cityIdTargets = this.cityIdTargets
    const cityIds = cityIdTargets.map(inputCity => parseInt(inputCity.value))
    return cities.map((city) => {
      const checked = this.shouldCheck(cityIds, city.id)
      this.removeInputHidden(cityIdTargets, city.id)
      return `<li class="${this.classNameModal()}::checks">
                  <label class="${this.classNameModal()}::check">
                      <input class="check-input" type="checkbox" value="${city.id}" ${checked ? "checked='checked'" : ''} name="city_ids[]" data-action="change->site--parts--search--desktop--component#changeFormValue click->site--parts--search--desktop--component#clickAreaCheckBox" data-site--parts--search--desktop--component-target="cityList">
                      <i></i>
                      <span>${city.name}</span>
                  </label>
              </li>`
    }).join('')
  }

  getRoute (e) {
    e.preventDefault()
    const { valuePref } = e.target.dataset
    const loading = this.loadingTargets.filter(element => element.dataset.valuePref === e.target.dataset.valuePref)
    const hasElement = this.routeCheckboxTargets.find(element => element.dataset.valuePref === valuePref)

    if (hasElement && hasElement.childElementCount <= 2) {
      const params = new URLSearchParams()
      params.append('prefecture_ids[]', valuePref)
      const path = '/api/areas/stations?' + params.toString()

      fetch(path)
        .then(data => data.json())
        .then(res => {
          this.hideLoading(loading[1])
          this.routes(res)
          this.scrollToParent(e.target)
        })
    }
  }

  routes (data) {
    const { id } = data.prefectures[0]
    const prefectureId = String(id)
    const routeList = this.createRouteList(data)
    const target = this.routeCheckboxTargets.find(element => element.dataset.valuePref === prefectureId)

    if (target && !target.childElementCount <= 2) {
      target.innerHTML += routeList
    }
  }

  createRouteList (data) {
    // 路線会社＞路線
    const prefectureId = String(data.prefectures[0].id)
    const companies = data.prefectures[0].company
    const routeList = companies.map(company => {
      const companyHeadingHTML = `
        <div class="${this.classNameModal()}::route-company-heading" data-value-company="${company.id}">${company.name}</div>
      `

      const routes = company.routes
      const routeHTML = routes.map(route => `
        <div class="${this.classNameModal()}::accordion-container">
          <div class="a${this.classNameModal()}::ccordion ${this.classNameModal()}::accordion-close">
            <div class="${this.classNameModal()}::accordion-heading ${this.classNameModal()}::check-all" data-value-pref="${prefectureId}" data-value-company="${company.id}" data-value-route="${route.id}" data-action="click->site--parts--search--modal--desktop--component#toggleAccordion click->site--parts--search--desktop--component#getStation">
              ${route.name}
            </div>
            <div class="${this.classNameModal()}::accordion-content" ${this.modalDesktopComponentTarget()}="accordion">
            <div class="${this.classNameModal()}::loading" data-value-route="${route.id}" data-value-pref="${prefectureId}" ${this.desktopComponentTarget()}="loading">
              <div class="${this.classNameModal()}::loader"></div>
            </div>
              <ul class="${this.classNameModal()}::check-container">
                <li class="${this.classNameModal()}::check-all">
                  <label class="${this.classNameModal()}::check">
                    <input class="${this.classNameModal()}::check-input" type="checkbox" value="all" ${this.desktopComponentTarget()}="checkboxAll" data-action="click->site--parts--search--desktop--component#checkAll">
                    <i></i>
                    <p class="${this.classNameModal()}::check-all-text">${route.name} すべて<span>を選択する</span></p>
                  </label>
                </li>
              </ul>
              <ul class="${this.classNameModal()}::check-container" data-value-route="${route.id}" data-value-pref="${prefectureId}" ${this.desktopComponentTarget()}="stationCheckbox checkboxes"></ul>
              </div>
          </div>
        </div>
      `).join('')

      return companyHeadingHTML + routeHTML
    }).join('')

    return routeList
  }

  checkAll (e) {
    const currentIndex = this.checkboxAllTargets.indexOf(e.currentTarget)
    const checkboxes = this.checkboxesTargets[currentIndex].querySelectorAll('input[type="checkbox"]')
    const allCheckbox = e.currentTarget
    const isChecked = allCheckbox.checked

    const spanElement = allCheckbox.parentElement.querySelector('span')
    if (spanElement) {
      spanElement.textContent = isChecked ? 'の選択を解除する' : 'を選択する'
    }

    function updateCheckboxes (isChecked) {
      checkboxes.forEach((checkbox) => {
        if (checkbox.checked !== isChecked) {
          checkbox.checked = isChecked
        }
      })

      // setTimeoutを使用して、非同期にイベントを発火する
      setTimeout(() => {
        checkboxes.forEach((checkbox) => {
          const event = new Event('click')
          checkbox.dispatchEvent(event)
        })
      }, 0)
    }

    updateCheckboxes(isChecked)

    this.setFacilitiesCount()
  }

  getStation (e) {
    e.preventDefault()
    const { valuePref, valueCompany, valueRoute } = e.target.dataset
    const loading = this.loadingTargets.filter(element => element.dataset.valueRoute === valueRoute && element.dataset.valuePref === valuePref)
    const hasElement = this.stationCheckboxTargets.find(element => element.dataset.valueRoute === valueRoute && element.dataset.valuePref === valuePref)

    if (hasElement && hasElement.childElementCount <= 2) {
      const params = new URLSearchParams()
      params.append('prefecture_ids[]', valuePref)
      const path = '/api/areas/stations?' + params.toString()

      fetch(path)
        .then(data => data.json())
        .then(res => {
          this.hideLoading(loading[0])
          this.createStationList(res, valueCompany, valuePref, valueRoute)
          this.scrollToParent(e.target)
        })
    }
  }

  createStationList (data, companyId, prefectureId, routeId) {
    const targetCompany = data.prefectures[0].company.find(item => item.id === parseInt(companyId))
    const targetRoute = targetCompany.routes.find(item => item.id === parseInt(routeId))
    const stations = targetRoute.stations
    const stationIdTargets = this.stationIdTargets
    // 県またぎの路線を選択した場合の対応。チェックボックスを作成する際に他のチェック済の路線のidを取得しこれから作成するstation idと比較し同一であればshouldCheckでtrueが返るようにする
    const selectedStationsValue = Array.from(this.stationListTargets).filter((e) => e.checked).map((e) => parseInt(e.value))
    const stationIds = stationIdTargets.map(inputValue => parseInt(inputValue.value)).concat(selectedStationsValue)

    const stationList = stations.map(station => {
      const checked = this.shouldCheck(stationIds, station.id)
      this.removeInputHidden(stationIdTargets, station.id)
      return `<li class="${this.classNameModal()}::checks">
          <label class="${this.classNameModal()}::check">
              <input class="check-input" type="checkbox" value="${station.id}" ${checked ? "checked='checked'" : ''} name="station_ids[]" data-action="change->site--parts--search--desktop--component#changeFormValue click->site--parts--search--desktop--component#clickAreaCheckBox" data-site--parts--search--desktop--component-target="stationList">
              <i></i>
              <span>${station.name}</span>
          </label>
      </li>`
    }).join('')

    Array.from(this.stationCheckboxTargets).some(element => {
      if (element.dataset.valuePref === prefectureId && element.dataset.valueRoute === routeId && element.childElementCount <= 2) {
        element.innerHTML += stationList
        return true
      }
    })
  }
}
